import { useEffect, useState } from "react";
import Cookies from "universal-cookie";

import { isNullOrEmpty } from "@utils/string";

const useAuth = (defaultHasAuthenticated: boolean) => {
  const cookies = new Cookies();
  const token = cookies.get("tkn");
  const [hasAuthenticated, sethasAuthenticated] = useState(defaultHasAuthenticated);

  useEffect(() => {
    sethasAuthenticated(!isNullOrEmpty(token));
  }, [token]);

  return { hasAuthenticated };
};

export default useAuth;
