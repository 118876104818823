import Card from "@afa-shared/afa-components/dist/Card";
import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import Tag from "@afa-shared/afa-components/dist/Tag";
import FAQList from "@components/FAQList";
import RichTextRenderer from "@components/RichTextRenderer";
import CFContentArea from "@components/cFContentArea";
import PageWrapper from "@containers/Layout/PageWrapper/PageWrapper";
import { defaultContext } from "@utils/contextHelper/getInitialContext";
import { getContentAreaMaxWidth } from "@utils/pageHelper";

import { Context } from "types/context/Context";

import faqPageStyles from "./faqPage.module.css";
import { FaqPageType_faq } from "./queries/contentfulTypes/FaqPageType";

const FaqPage = (props: any) => {
  const faqPage = props?.pageData as FaqPageType_faq;
  const context: Context = { ...defaultContext, faqPageContent: true };

  return (
    <PageWrapper
      imageData={null}
      topImageText={""}
      columns={12}
      relatedContentHeading={""}
      relatedContentCollection={false}
      contentfulPreviewEntryId={faqPage?.sys?.id}
      context={context}
      useReCapcha={false}
      shouldRemoveBottomMargin={false}
    >
      <Grid container desktop={8}>
        <section className={faqPageStyles.introductionSection}>
          {faqPage?.tagName && (
            <Tag
              className={faqPageStyles.tag}
              label={faqPage.tagName}
              data-contentful-entry-id={faqPage.sys?.id}
              data-contentful-field-id="tagName"
            />
          )}
          <Heading
            variant="h1"
            data-contentful-entry-id={faqPage.sys?.id}
            data-contentful-field-id="question"
          >
            {faqPage?.question}
          </Heading>

          {faqPage?.answer?.json && (
            <div data-contentful-entry-id={faqPage.sys?.id} data-contentful-field-id="answer">
              <RichTextRenderer json={faqPage?.answer?.json} links={faqPage?.answer?.links} />
            </div>
          )}
        </section>

        {faqPage?.contentAreaCollection?.items.length > 0 && (
          <Grid className={faqPageStyles.faqContentArea}>
            <CFContentArea
              key="faqPage-main-area"
              desktopColumns={1}
              mobileColumns={1}
              tabletColumns={1}
              contentAreaItems={faqPage?.contentAreaCollection?.items}
              maxWidth={getContentAreaMaxWidth(8)}
              context={context}
            />
          </Grid>
        )}
        {faqPage?.relatedContentArea && (
          <div className={faqPageStyles.faqRelatedContent}>
            <FAQList
              faqListBlock={faqPage.relatedContentArea}
              uniqueKey={"faqRelatedContent"}
              context={context}
            />
          </div>
        )}
        <div className={faqPageStyles.faqTeaserBlock}>
          <Card
            linkUrl={"/om-oss"}
            nextImage={<img src="/images/afa-blue-background.svg" alt="Afa Försäkring logotyp" />}
            direction={"row"}
            heading={"Om Afa Försäkring"}
            text={
              "Afa Försäkring ger ekonomiskt stöd vid sjukdom, arbetsskada, arbetsbrist, dödsfall och föräldraledighet. 5 miljoner anställda är försäkrade via jobbet."
            }
            linkText={"Om Afa Försäkring"}
            reverse={true}
          />
        </div>
      </Grid>
    </PageWrapper>
  );
};

export default FaqPage;
