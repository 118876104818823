import { useRouter } from "next/router";
import { useState } from "react";

import Button from "@afa-shared/afa-components/dist/Buttons/Button";
import ChatCard from "@afa-shared/afa-components/dist/ChatCard";
import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import TextInput from "@afa-shared/afa-components/dist/Inputs/TextInput";
import Text from "@afa-shared/afa-components/dist/Text";
import PageWrapper from "@containers/Layout/PageWrapper/PageWrapper";
import StandardPage from "@containers/StandardPage";
import { defaultContext } from "@utils/contextHelper/getInitialContext";

import { BrandPortalLoginStructure } from "types/BrandPortalLoginStructure";
import { Context } from "types/context/Context";

import brandPortalLoginStyles from "./BrandPortalLogin.module.css";

type ErrorStateType = {
  message: string;
  errorExist: boolean;
};

const BrandPortalLogin = (props: any) => {
  const vplPage = props?.page?.slug?.data as BrandPortalLoginStructure;
  const desktopColumns =
    props?.page?.slug?.content?.__typename?.toLowerCase() ===
    ("landingpage" || "extendedlandingpage")
      ? 12
      : 8;
  const page404 = props?.page404;

  const imageData = vplPage?.ivMainImage?.[0];

  const [errorState, setErrorState] = useState<ErrorStateType>({
    message: null,
    errorExist: false,
  });
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const router = useRouter();

  const isBrandPortal = router.asPath.startsWith("/varumarke");

  const context: Context = { ...defaultContext };

  const handleChange = (e) => {
    setErrorState({ message: null, errorExist: false });
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const credentials = { password };
    const res = await fetch("/api/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    });
    if (res.ok) {
      setErrorState({ message: null, errorExist: false });
      router.reload();
    } else {
      setErrorState({ message: "Felaktigt lösenord", errorExist: true });
      setLoading(false);
    }
  };

  return page404 ? (
    <StandardPage pageData={page404?.slug?.data} is404={true} />
  ) : (
    <PageWrapper
      imageData={imageData}
      topImageText={vplPage?.topImageText}
      columns={12}
      relatedContentHeading={""}
      relatedContentCollection={[]}
      context={context}
      useReCapcha={false}
      shouldRemoveBottomMargin={false}
      className={brandPortalLoginStyles.brandPortalLogin}
    >
      <Grid desktop={desktopColumns}>
        {isBrandPortal && (
          <Heading variant="h1" dataTestId="varumarke">
            {vplPage?.heading}
          </Heading>
        )}
        {isBrandPortal && vplPage?.preamble && (
          <Text variant={"preamble"} children={vplPage?.preamble} />
        )}
      </Grid>
      <Grid container desktop={desktopColumns} margin={"0 auto 3.5rem"}>
        <ChatCard>
          <Grid direction={"column"} alignItems={"center"}>
            {isBrandPortal ? (
              <Heading variant="h2" as="h3">
                Logga in i varumärkesportalen
              </Heading>
            ) : (
              <Heading variant="h2" as="h3">
                Logga in för att se innehållet
              </Heading>
            )}
            <Text variant="paragraph" className={brandPortalLoginStyles.text}>
              {isBrandPortal ? (
                <>
                  <span>
                    För att komma åt innehållet i varumärkesportalen behöver du uppge ett lösenord.{" "}
                  </span>
                  <span>
                    Har du inte sedan tidigare ett lösenord, kan du få det genom att kontakta{" "}
                    <a href="mailto:varumarke@afaforsakring.se">varumarke@afaforsakring.se</a>
                  </span>
                </>
              ) : (
                <>
                  <span>För att komma åt innehållet så behöver du uppge ett lösenord. </span>
                </>
              )}
            </Text>
            <form className={brandPortalLoginStyles.form}>
              <div className={brandPortalLoginStyles.inputWrapper}>
                <TextInput
                  type="password"
                  label="Lösenord"
                  id="password"
                  name="password"
                  value={password}
                  error={errorState.message}
                  onChange={(e: any): void => handleChange(e)}
                />

                <Button
                  className={`${brandPortalLoginStyles.submitButton} ${
                    errorState.errorExist ? brandPortalLoginStyles.hasError : ""
                  }`}
                  buttonType="primary"
                  label={loading ? "Laddar..." : "Logga in"}
                  onClick={(e) => handleSubmit(e)}
                />
              </div>
            </form>
          </Grid>
        </ChatCard>
      </Grid>
    </PageWrapper>
  );
};

export default BrandPortalLogin;
